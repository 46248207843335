body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  color: #282832;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --amplify-font-family: 'Montserrat', sans-serif;
  --amplify-primary-color: #d1233e;
  --amplify-primary-tint: #d1233e;
  --amplify-primary-shade: #d1233e;
  --amplify-secondary-color: #282832;
  --amplify-secondary-tint: #282832;
}
