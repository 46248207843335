.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.grid {
  padding: 16px;
}

.message {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.6;
  margin: 0;
}

.button {
  padding: 7px 21px;
  font-size: 0.9375rem;
  border: 1px solid #a9a9ad;
  color: #282832;
  min-width: 64px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  text-transform: uppercase;
  cursor: pointer;
  background-color: transparent;
}

.button:hover {
  background-color: #f7f7fb;
}