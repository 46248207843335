.absoluteLogo {
  position: absolute;
  left: 0;
  right: 0;
}

.logo {
  margin-left: auto;
  margin-right: auto;
  width: 240px;
}

@media only screen and (max-height: 609px) {
  .logo {
    display: none;
  }
}

@media only screen and (min-height: 610px) and (max-height: 689px) {
  .logo {
    top: 5vh;
  }
}

@media only screen and (min-height: 690px) and (max-height: 799px) {
  .logo {
    top: 10vh;
  }
}

@media only screen and (min-height: 800px) and (max-height: 999px) {
  .logo {
    top: 15vh;
  }
}

@media only screen and (min-height: 1000px) {
  .logo {
    top: 20vh;
  }
}